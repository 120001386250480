@tailwind base;
@tailwind components;
@tailwind utilities;

/* global styles go here */
body,
html,
#__next {
  @apply h-full scroll-smooth;
  overscroll-behavior: none;
  @apply font-secondary;
  @apply 2xl:text-m1;
  @apply bg-warm-white;
  overflow-x: clip;
  font-family: "Karla", sans-serif;
}

#__next > footer {
  top: 100vh;
  position: sticky;
}

@layer base {
  @font-face {
    font-family: ITCAvantGardeGothicProBold;
    src: url("fonts/ITCAvantGardeGothicProBold/font.woff2") format("woff2");

    font-display: swap;
  }
  @font-face {
    font-family: "SkippySharp";
    src: url("fonts/SkippySharp/font.woff2") format("woff2");

    font-display: swap;
  }
}

.container {
  @apply px-6 2xl:px-12;
}

.container-inset {
  @apply lg:px-20 xl:px-24 max-w-screen-xl 2xl:max-w-screen-2xl mx-auto;
}

.photo-bean {
  @apply h-[374px] w-[450px] xl:h-[492px] xl:w-[592px] 2xl:h-[565px] 2xl:w-[680px];
}

/* Text styles */
h1 {
  @apply font-primary text-l2 lg:text-xl3 2xl:text-xl4;
}

h2 {
  @apply font-primary text-l1 lg:text-l2 2xl:text-l3;
}

h3 {
  @apply font-bold text-m1 lg:text-m2 2xl:text-l1;
}

.subheading {
  @apply font-semibold text-m2 lg:text-m3 2xl:text-xl1;
}

.eyebrow-1 {
  @apply font-semibold uppercase text-m2 lg:text-l1 2xl:text-l2 tracking-wider;
}

.eyebrow-2 {
  @apply font-bold uppercase text-m1 lg:text-m2 2xl:text-m3 tracking-wider;
}

.callout {
  @apply font-tertiary text-l1;
}

.prose {
  @apply font-medium text-base 2xl:text-m3;
}

.prose b {
  @apply font-extrabold;
}

p {
  @apply pb-4 last:pb-0;
}

.fine-print {
  @apply text-sm 2xl:text-m1;
}

.rich-text-ul,
.rich-text-ol {
  font-family: theme("fontFamily.secondary");
  margin-bottom: theme("spacing.5");
  @apply last-of-type:pb-0;
}

.rich-text-ul {
  list-style-type: disc;
}

.rich-text-ol {
  list-style-type: decimal;
}

.rich-text-ul li,
.rich-text-ol li {
  margin-left: theme("spacing.2");
}

.rich-text-ul li::marker,
.rich-text-ol li::marker {
  font-family: theme("fontFamily.secondary");
}

/* End Text styles */

/* Call to Action */
.primary-link {
  @apply no-underline px-7 py-4 text-m1 2xl:text-m2 text-blue font-secondary font-bold bg-kelly-to-lime rounded-[30px] xl:rounded-[50px] uppercase;
}

.prose .primary-link {
  @apply my-8;
}

#full-page-header .prose .primary-link {
  @apply my-4;
}

#full-page-header .prose a:not(.primary-link) {
  @apply text-green-lime decoration-green-lime;
  font-size: inherit;
}

#full-page-header .prose a:not(.primary-link):hover {
  @apply text-blue-bright decoration-blue-bright;
}

.primary-link::before {
  content: "";
  position: absolute;
  inset: 0;
  background-color: theme("colors.blue.bright");
  transform: translateX(-100%);
  transition: transform 0.2s ease-in-out;
}

.primary-link:hover::before {
  transform: translateX(0);
}

.primary-link span {
  position: relative;
  z-index: 10;
}

.secondary-light-link,
.secondary-dark-link {
  @apply inline;
}

.secondary-dark-link,
.secondary-dark-link:visited {
  @apply no-underline text-white hover:text-green-kelly uppercase decoration-green-kelly border-b-2 border-b-green-kelly hover:border-transparent text-m1 font-bold font-secondary transition-all duration-300 gap-2 relative w-max;
}

.secondary-light-link,
.secondary-light-link:visited {
  @apply no-underline text-blue hover:text-green-a11y uppercase decoration-green-kelly border-b-2 border-b-green-kelly hover:border-transparent text-m1 font-bold font-secondary transition-all duration-300 gap-2 relative w-max;
}

.secondary-dark-link::after,
.secondary-light-link::after {
  content: url("public/assets/right-arrow.svg");
  height: 20px;
  width: 20px;
  display: inline-block;
  position: relative;
  top: 4px;
  margin-left: 0.25rem;
}
.secondary-dark-link::before,
.secondary-light-link::before {
  content: url("public/assets/right-circle.svg");
  height: 20px;
  width: 20px;
  display: inline-block;
  scale: 0;
  position: absolute;
  bottom: 0px;
  right: 0px;
  transition: all;
  transition-duration: 300ms;
}
.secondary-dark-link:hover::before,
.secondary-light-link:hover::before {
  scale: 1;
}

.tertiary-link {
  @apply px-7 py-4 text-m1 2xl:text-m3 text-blue font-secondary font-bold bg-white hover:bg-dark-blue-to-blue hover:text-white rounded-[30px] xl:rounded-[50px] uppercase;
}

.ghost-dark-link {
  @apply px-7 py-4 text-m1 text-blue font-secondary font-bold border-2 border-green-kelly hover:border-blue-bright rounded-[30px] xl:rounded-[50px];
}

.ghost-light-link {
  @apply px-7 py-4 text-m1 text-white font-secondary font-bold border-2 border-white hover:border-blue-bright rounded-[30px] xl:rounded-[50px];
}

.inline-link {
  @apply text-purple-bright font-bold font-secondary text-base 2xl:text-m3 hover:underline underline-offset-4 decoration-purple decoration-solid;
}

.inline-link:hover {
  @apply text-green-a11y decoration-green-a11y;
}

.fine-print .inline-link {
  @apply text-sm 2xl:text-m1;
}

.phone-link,
.email-link {
  @apply flex w-max items-center gap-2 font-secondary text-base font-medium 2xl:text-m2 text-white hover:underline;
}

.phone-link::before,
.email-link::before {
  content: "";
  display: block;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.phone-link::before {
  background-image: url("public/assets/phone-icon.svg");
  width: 20px;
  height: 28px;
}

.email-link::before {
  background-image: url("public/assets/email-icon.svg");
  height: 20px;
  width: 28px;
}

/* End Call to Action */

/* Main Menu */

/* mobile menu button */
.hamburger-btn path {
  transition: all 0.3s ease-in-out;
}

.hamburger-btn svg.open .top-line {
  transform: rotate(45deg);
  transform-origin: 35% 45%;
}

.hamburger-btn svg.open .middle-line {
  opacity: 0;
}

.hamburger-btn svg.open .bottom-line {
  transform: rotate(-45deg);
  transform-origin: 36% 52%;
}
/* End mobile menu button */

/* prevent CTA btn from wrapping right at lg breakpoint */
header nav ~ a {
  white-space: nowrap;
}

nav.menu::-webkit-scrollbar {
  display: none;
}

nav.menu:not(:has(.open-submenu)) {
  width: 100%;
}

@screen xl {
  .header-submenu-background {
    background-image: theme("backgroundImage.plus-pattern"), theme("backgroundImage.blue-to-dark-blue");
    background-repeat: no-repeat;
    background-position: bottom right;
  }
}

.two-column-list .rich-text-ul {
  position: relative;
}

.two-column-list .rich-text-ul li:last-of-type {
  @apply max-w-44 callout  pt-8 ml-10 xl:ml-0 mb-4 xl:mb-0  text-green-kelly xl:right-12 rotate-callout before:content-[''] before:bg-callout-arrow before:w-16 before:h-12 before:block before:bg-no-repeat before:-left-8 before:-top-0 before:absolute -bottom-24 sm:-bottom-[72px] lg:-bottom-20 xl:-bottom-16 hidden xs:block;
  position: absolute;
  list-style-type: none;
}

/* End Main Menu */

/* Footer */
.social-icon path {
  fill: white;
  transition-property: all;
  transition-duration: 200ms;
}

.social-icon:hover path {
  fill: theme("colors.blue.bright");
}
/* End Footer */

nav.menu > ul > li > .top-level {
  /* color: white; */
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100dvw -48px;
  text-transform: uppercase;
  font-weight: 700;

  @apply xl:mr-8 xl:w-auto text-l1 xl:text-m1 pb-8 md:pb-0;
}

nav.menu > ul > li > .top-level::after {
  content: "";
  background-image: url("public/assets/header-chevron.svg");
  background-size: contain;
  width: 20px;
  height: 20px;
  display: block;
  transition: all;
  transition-duration: 300ms;
  transform: rotate(270deg);

  @apply xl:rotate-0 xl:w-4 xl:h-4;
}
nav.menu > ul > li:hover > .top-level::after {
  transform: rotate(270deg) translateY(4px);
  @apply xl:rotate-180;
}

/* Submenu */
nav.menu > ul:has(li.open-submenu) {
  transition: all;
  transition-duration: 300ms;
}

nav.menu > ul:has(li.open-submenu) > li > .top-level {
  @apply xl:flex;
}

nav.menu > ul > li > .submenu {
  cursor: default;
  @apply hidden md:block overflow-hidden xl:flex w-full xl:left-0 xl:right-0 xl:top-full xl:absolute max-h-0 opacity-0 transition-opacity duration-500;
}

nav.menu > ul > li.open-submenu > .submenu {
  @apply opacity-100 max-h-full xl:min-h-96 xl:py-12;
}

nav.menu > ul .submenu .submenu-label {
  gap: 4px;
  align-items: center;
  @apply xl:block xl:cursor-text text-m3 lg:text-l1 xl:gap-0;
}

nav.menu > ul > li.open-submenu > .submenu .submenu-label::before {
  content: "";
  background-image: url("public/assets/header-chevron.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
  display: block;
  transform: rotate(270deg);
  transition: all;
  transition-duration: 300ms;

  @apply xl:hidden;
}
nav.menu > ul > li.open-submenu > .submenu .submenu-label:hover::before {
  transform: rotate(270deg) translateY(4px);
}

/* Section Container Backgrounds */
.section-container-purple-gradient {
  @apply bg-purple-gradient;
  @apply text-white;
}
.section-container-purple-gradient .eyebrow {
  @apply text-green-kelly;
}

.section-container-white {
  @apply bg-white;
  @apply text-blue;
}
.section-container-white .eyebrow {
  @apply text-purple;
}

.section-container-warm-white {
  @apply bg-warm-white;
  @apply text-blue;
}
.section-container-warm-white .eyebrow {
  @apply text-purple;
}

/* Carousel */

.arrow-svg-gradient {
  fill: url(#svg-gradient);
}

.carousel .carousel__slider-tray-wrapper {
  transition: transform 0.5s ease-in-out;
}

.carousel .carousel__slide {
  transition: opacity 0.5s ease-in-out;
}

.carousel .carousel__slide--hidden {
  opacity: 0;
}

/* Basic Animations */

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Define the keyframes for the pulsing effect */
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.pulse {
  animation: pulse 1s infinite;
}

.fade-in {
  animation: fadeIn ease 5s;
}

/* Image Mask (Bean Shape) */
.masked-image {
  -webkit-mask-image: url("/assets/mask-photo-bean.svg");
  mask-image: url("/assets/mask-photo-bean.svg");
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: cover;
  mask-size: cover;
  background-color: theme("colors.blue.dark");
}

.masked-video {
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: cover;
  mask-size: cover;
  background-color: theme("colors.blue.dark");
}

/* Automatically pad sections that appear directly below the header on mobile screen sizes (to avoid collisions with the photo bean/blob*/
#full-page-header + section:first-of-type,
#full-page-header + .section-container > section:first-of-type {
  @apply pt-64 lg:pt-12;
}

#video-header + .section-container section:first-of-type {
  @apply pt-32 lg:pt-16;
}

#full-page-header:has(+ .section-container section > div.image-and-content) {
  @apply lg:-mb-28;
}

.section-container ~ :not(#full-page-header) > .container-section:first-of-type,
.section-container ~ :not(#video-header) > .container-section:first-of-type {
  @apply lg:pt-16;
}

.container-section > .three-column-card {
  @apply lg:pt-0 xl:pt-8;
}

#full-page-header + .section-container section:first-child > .image-and-content .image-content-section {
  @apply -mt-64 pt-60 lg:-mt-8 xl:-mt-8 lg:pt-0;
}

#full-page-header + .section-container section.overlap-header {
  @apply -mt-64 pt-60 lg:-mt-36 xl:-mt-36 lg:pt-0;
}

/* Rich text highlight treatment */
.highlight-green {
  @apply bg-highlight-green bg-no-repeat bg-center bg-contain px-1;
}

.highlight-purple {
  @apply bg-highlight-purple bg-no-repeat bg-center bg-cover px-1;
}

.team-grid .tooltip {
  @apply py-2 px-4 rounded-2xl text-sm max-w-screen-lg text-blue font-secondary bg-kelly-to-lime leading-3;
}

.team-grid .tooltip-arrow {
  @apply bg-blue;
}
